<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t("staff.staff_head") }} </h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t("staff.staff_list") }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <!-- <span>
              <date-picker-input
                ref="startDate"
                :date="dateFrom"
                label="วันที่สมัคร(จาก)"
                @setValue="dateFrom = $event"
              />
            </span>
            <span>
              <date-picker-input ref="endDate" :date="dateTo" label="วันที่สมัคร(ถึง)" @setValue="dateTo = $event" />
            </span> -->
            <span>
              <v-select v-model="role_id" :label="$t('staff.staff_status')" :items="roles" item-text="label" outlined
                dense />
              <!-- <select-input label="สถานะ" /> -->
            </span>
            <v-btn color="primary" @click="searchStaff" class=""> {{ $t("search") }} </v-btn>
          </v-row>
        </v-card-actions>
        <br />
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <span>
              <v-select
                v-model="selectedLoginMethod"
                :label="$t('staff.staff_login_method')"
                :items="loginMethods"
                item-text="title"
                item-value="value"
                outlined
                dense
              />
            </span>
            <v-btn color="primary" 
                    @click="updateLoginMethod" 
                    class="ml-2"
                    :disabled="isUpdateButtonDisabled"
            >
              {{ $t("staff.staff_update_login_method") }}
            </v-btn>
          </v-row>
        </v-card-actions>

        <br />
        <v-card-actions>
          <v-row no-gutters>
            <v-btn color="primary" @click="linkToAdd">{{ $t("staff.staff_create") }} </v-btn>
            <v-btn color="success" @click="roleDialog = true">role</v-btn>
            <v-spacer />
            <span class="px-3 mt-5">{{ $t("search") }} :</span>
            <div style="width: 150px" class="mt-5">
              <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
            </div>
          </v-row>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="visibleHeaders" :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')" :options.sync="pagination" :server-items-length="pagination.totalItems"
            :items-per-page="pagination.rowsPerPage" :page.sync="pagination.page" :loading="loading" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :items="members">
            <template v-slot:[`item.bank`]="{ item }">
              <img v-if="item.bank" alt="Avatar" width="25px" :src="require(`@/assets/images/logos/banks/${item.bank}.png`)" />
            </template>
            <template v-slot:[`item.username`]="{ item }">
              <v-btn color="warning" @click="showMemberDetail(item)">
                {{ item.username }}
              </v-btn>
            </template>

            <!-- // Update the template for 2FA button -->
            <template v-slot:[`item.status_2fa`]="{ item }">
              <v-btn 
                v-if="activeLoginMethod === '2fa'"
                :color="item.status_2fa === 1 ? 'success' : 'error'"
                @click="update2FAStatus(item)"
              >
                {{ item.status_2fa === 1 ? '2FA Enabled' : '2FA Disabled' }}
              </v-btn>
            </template>

            <!-- // Update the template for Line OTP button -->
            <template v-slot:[`item.status_line_otp`]="{ item }">
              <v-btn 
                v-if="activeLoginMethod === 'lineOtp'"
                :color="item.status_line_otp === 1 ? 'success' : 'error'"
                @click="updateLineOTPStatus(item)"
              >
                {{ item.status_line_otp === 1 ? 'Line OTP Enabled' : 'Line OTP Disabled' }}
              </v-btn>
            </template>

            <!-- // Update the template for Telegram OTP button -->
            <template v-slot:[`item.status_telegram_otp`]="{ item }">
              <v-btn
                v-if="activeLoginMethod === 'telegramOtp'"
                :color="item.status_telegram_otp === 1 ? 'success' : 'error'"
                @click="updateTelegramOTPStatus(item)"
              >
                {{ item.status_telegram_otp === 1 ? 'Telegram OTP Enabled' : 'Telegram OTP Disabled' }}
              </v-btn>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="success" dark v-bind="attrs" v-on="on">
                    <v-icon small color="white">mdi-square-edit-outline</v-icon>
                    <span class="px-3 white--text">{{ $t("staff.manage") }} </span>
                    <v-icon small color="white">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="linkToEdit(item.id)">
                    <v-list-item-title>
                      <v-icon small color="warning">mdi-square-edit-outline</v-icon>
                      <span class="px-4">{{ $t("staff.staff_edit") }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider />
                  <v-list-item @click="deleteStaff(item)">
                    <v-list-item-title>
                      <v-icon small color="error">mdi-delete</v-icon>
                      <span class="px-4">{{ $t("staff.staff_delete") }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
      <staff-show-detail :dialog="showDetail" :username="currentMemberDetail.username"
        :password="currentMemberDetail.password" @setDialog="showDetail = $event" />
      <create-role :dialog="roleDialog" @setDialog="roleDialog = $event" />
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import StaffShowDetail from '@/views/staff/StaffShowDetail.vue'
import CreateRole from '@/components/staff/roles/CreateRole.vue'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
    StaffShowDetail,
    CreateRole,
  },
  // add computed section for visibleHeader in table
  computed: {

    loginMethods() {
      return this.availableMethods.map(method => {
        switch(method) {
          case '2fa': return { title: '2FA', value: '2fa' };
          case 'lineOtp': return { title: 'Line OTP', value: 'lineOtp' };
          case 'telegramOtp': return { title: 'Telegram OTP', value: 'telegramOtp' };
          default: return null;
        }
      }).filter(Boolean);
    },
    
    validMethods() {
      return this.availableMethods;
    },

    // if only have 2fa don't show update button
    isUpdateButtonDisabled() {
      return this.loginMethods.length === 1;
    },

    // show / hide column
    visibleHeaders() {
      return this.headers.filter(header => {
        if (header.value === 'status_2fa') {
          const shouldShow = this.activeLoginMethod === '2fa'
          return shouldShow
        }
        if (header.value === 'status_line_otp') {
          const shouldShow = this.activeLoginMethod === 'lineOtp'
          return shouldShow
        }
        if (header.value === 'status_telegram_otp') {
          const shouldShow = this.activeLoginMethod === 'telegramOtp'
          return shouldShow
        }
        return true;
      });
    }
  },
  data() {
    return {
      role_id: '',
      startDate: '',
      endDate: '',
      menu: false,
      showDetail: false,
      roleDialog: false,
      toggle_exclusive: 0,
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      loading: false,
      searchData: null,

      selectedLoginMethod: '2fa',
      activeLoginMethod: '2fa',
      availableMethods: ['2fa'], // Default to just 2FA 

      headers: [
        {
          text: this.$t("staff.staff_phone"),
          value: 'phone',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t("staff.staff_email"),
          value: 'email',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t("staff.staff_fullname"),
          value: 'name',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t("staff.staff_role"),
          value: 'role',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t("staff.staff_line"),
          value: 'line',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t("staff.staff_create_date"),
          value: 'date',
          width: '138px',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t("staff.staff_editor"),
          value: 'editing',
          width: '138px',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t("staff.staff_edit_date"),
          value: 'editing_time',
          width: '138px',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t("staff.staff_2fa"),
          value: 'status_2fa',
          align: 'center',
          sortable: false,
          show: false // Initially hidden
        },
        {
          // add line otp column in table
          text: this.$t("staff.staff_line_otp"),
          value: 'status_line_otp',
          align: 'center',
          sortable: false
        },
        {
          // add telegram otp column in table
          text: this.$t("staff.staff_telegram_otp"),
          value: 'status_telegram_otp',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t("staff.manage"),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      members: [],
      roles: [],
      dummy: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      search: '',
    }
  },

  async created() {
    try {
      // Add log page
      await this.addLogPage()
      // Get roles
      await this.$store.dispatch('getRoleData').then(result => {
        this.roles = result
      })

      // Get available methods first (when token is available)
      await this.fetchAvailableLoginMethods();

      // Initialize login method
      await this.initializeLoginMethod()
      
      // Get initial staff list (only once)
      await this.getStaff(1, this.pagination.itemsPerPage, null, null)

    } catch (error) {
      console.error('Error in created hook:', error)
    }
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        // await this.getStaff(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, null, this.searchData)
        if (cont && !this.dummy)
          await this.getStaff(newValue.page, newValue.itemsPerPage, null, null, null, this.searchData)
        if (cont && this.dummy)
          await this.getStaff(newValue.page, newValue.itemsPerPage, null, null, null, this.searchData)
      },
      deep: true,
    },
  },
  // async mounted() {
  //   this.fetchAvailableLoginMethods();
  // },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          (data = {
            ip: res.data.ip,
            name: 'พนักงาน',
            url: window.location.href,
            detail: 'ระบบจัดการพนักงาน',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async searchStaff() {
      this.dummy = false
      await this.getStaff(1, this.pagination.itemsPerPage, null, null, this.role_id, this.searchData)
      this.pagination.page = 1
    },

    async getStaff(page, row, time_from, time_to, role_id, search) {
      this.loading = true
      this.members = []
      let rs = await this.$store.dispatch('getEmployee', {
        page: page,
        rows: row,
        ...(!search ? { page: page } : {}),
        ...(!search ? { rows: row } : {}),
        ...(time_from ? { time_from: this.formatDateYYYY(time_from) } : {}),
        ...(time_to ? { time_to: this.formatDateYYYY(time_to) } : {}),
        ...(role_id ? { role_id: role_id } : {}),
        ...(search ? { search: search } : {})
      })
      this.pagination.totalItems = rs.count

      console.log('Raw API response:', rs); // Add this debug log

      try {
        rs.data.forEach(element => {
          console.log('Processing element:', element); // Add this debug log
          if (element) {
            this.members.push({
              id: element.id,
              name: element.name ? element.name : '',
              email: element.email ? element.email : '',
              line: element.line ? element.line : '',
              phone: element.phone ? element.phone : '',
              role: element.role.name,
              username: element.username,
              date: moment(element.created_at).format('YY-MM-DD HH:mm'),
              editing: element.adminEditingDetail ? element.adminEditingDetail?.adminEditing?.name : null,
              editing_time: element.adminEditingDetail
                ? moment(element.adminEditingDetail?.updatedAt).format('YY-MM-DD HH:mm')
                : null,
              status_2fa: element.status_2fa,
              status_line_otp: element.status_line_otp,
              status_telegram_otp: element.status_telegram_otp,
            })
          }
        })
        // console.log('Processed member data:', memberData); // Add this debug log
        console.log('Processed members data:', this.members.map(m => ({
          id: m.id,
          username: m.username,
          status_line_otp: m.status_line_otp,
          status_2fa: m.status_2fa,
          status_telegram_otp: m.status_telegram_otp
        })))

      } catch (error) {
        console.error('Error processing staff data:', error)
      }

      this.loading = false
    },

    showMemberDetail(value) {
      this.currentMemberDetail = value
      this.showDetail = true
    },
    linkToEdit(id) {
      this.$router.push({ name: 'staff-form', params: { id } })
    },
    linkToAdd() {
      this.$router.push({ name: 'staff-form-add' })
    },
    async update2FAStatus(item) {
      const { id, phone, status_2fa } = item
      if (status_2fa === 1) {
        this.$swal
          .fire({
            icon: 'warning',
            title: `${this.$t("staff.staff_delete_2fa")} ${phone} ?`,
            html: `
            <div>
              <p>`+ this.$t("staff.staff_enter_six_digit") + `</p>
            </div>
          `,
            input: 'text',
            inputAttributes: {
              inputmode: 'numeric',
              pattern: '[0-9]*',
              maxlength: 6,
            },
            inputValidator: value => {
              if (!value || isNaN(value)) {
                return this.$t("staff.staff_alert_number")
              }
              if (value && value.length !== 6) {
                return this.$t("staff.staff_alert_digit")
              }
            },
            inputPlaceholder: this.$t("staff.staff_code_here"),
            showCancelButton: true,
            confirmButtonText: this.$t("confirm"),
            cancelButtonText: this.$t("cancel"),
          })
          .then(async result => {
            if (result.isConfirmed) {
              const sendData = {
                id: id,
                pin: result.value,
              }
              const res = await this.$store.dispatch('verify2fa', sendData)
              if (res.status) {
                await this.$store.dispatch('delete2fa', sendData)
                await this.getStaff(1, 25, null, null)
                this.$swal.fire(this.$t("staff.staff_closed_2fa"), '', 'success')
              } else {
                this.$swal.fire(this.$t("staff.staff_unclose_2fa"), '', 'error')
              }
              this.loading = false
            }
          })
      } else {
        const QRcode = await this.$store.dispatch('generate2fa', { id })
        await this.getStaff(1, 25, null, null)

        this.$swal({
          title: `${this.$t("staff.staff_open_2fa")} ${phone} ?`,
          html: `
            <div>
              <img src="${QRcode}" alt="Image" style="width: 100%;">
              <p>`+ this.$t("staff.staff_enter_six_digit") + `</p>
            </div>
          `,
          input: 'text',
          inputAttributes: {
            inputmode: 'numeric',
            pattern: '[0-9]*',
            maxlength: 6,
          },
          inputValidator: value => {
            if (!value || isNaN(value)) {
              return this.$t("staff.staff_alert_number")
            }
            if (value && value.length !== 6) {
              return this.$t("staff.staff_alert_digit")
            }
          },
          inputPlaceholder: this.$t("staff.staff_code_here"),
          showCancelButton: true,
          confirmButtonText: this.$t("confirm"),
          cancelButtonText: this.$t("cancel"),
        }).then(async result => {
          if (result.isConfirmed) {
            const pin = result.value
            const sendData = {
              id,
              pin,
            }
            const res = await this.$store.dispatch('register2fa', sendData)
            if (res.status) {
              this.$swal.fire(this.$t("staff.staff_opened_2fa"), '', 'success')
            } else {
              await this.$store.dispatch('deleteGenerate2fa', sendData)
              await this.getStaff(1, 25, null, null)
              this.$swal.fire(this.$t("staff.staff_unopen_2fa"), '', 'error')
            }
            this.loading = false
          } else {
            const sendData = {
              id: id,
              pin: '000000',
            }
            await this.$store.dispatch('deleteGenerate2fa', sendData)
            await this.getStaff(1, 25, null, null)
          }
        })
      }
    },
    async deleteStaff(item) {
      // await this.xxxx()
      this.$swal
        .fire({
          icon: 'warning',
          title: this.$t("alert"),
          text: `${this.$t("staff.staff_delete_confirm")} ${item.username}`,
          showCancelButton: true,
          confirmButtonText: this.$t("confirm"),
          cancelButtonText: this.$t("cancel"),
        })
        .then(async result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            await this.$store.dispatch('deleteemployee', item.id)
            this.$swal.fire(this.$t("staff.staff_delete_complete"), '', 'success')
            await this.getStaff(1, 25, null, null)
            //  this.pagination.totalItems = result.count
            // this.value = result.data
            this.loading = false
          }
        })
    },
    async searchKeyword(value) {
      this.dummy = true
      this.searchData = value
      await this.getStaff(1, this.pagination.itemsPerPage, null, null, null, value)
      this.pagination.page = 1
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },

    async updateLoginMethod() {
  // Use the computed validMethods instead of hardcoded array
  if (!this.validMethods.includes(this.selectedLoginMethod)) {
      this.$swal.fire({
          icon: 'error',
          title: this.$t('staff.update_failed'),
          text: `Method must be one of: ${this.validMethods.join(', ')}`
        });
        return;
      }

      try {

        // Show confirmation dialog
        const confirmResult = await this.$swal({
          title: this.$t('staff.staff_confirm_login_method_change_title'),
          html: `
            <div>
              <p>${this.$t('staff.staff_confirm_login_method_change_message')} <strong>${this.selectedLoginMethod}</strong>?</p>
              <p>${this.$t('staff.staff_confirm_login_method_change_impact')}</p>
            </div>
          `,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('staff.staff_confirm_change'),
          cancelButtonText: this.$t('staff.staff_cancel')
        });

        // If user cancels, reset selection and exit
        if (!confirmResult.isConfirmed) {
          this.selectedLoginMethod = this.activeLoginMethod;
          return;
        }

        const payload = {
          method: this.selectedLoginMethod 
        }

        // from src/store/index.js it should be this 
        // await this.$store.dispatch('settingWeb/updateLoginMethod', payload)
        // but since we not set namespaced: true, in export default it using this instead
        await this.$store.dispatch('updateLoginMethod', payload)

        this.activeLoginMethod = this.selectedLoginMethod
        
        this.$swal.fire({
          icon: 'success',
          title: this.$t('staff.staff_login_method_updated'),
          showConfirmButton: false,
          timer: 1500
        })
      } catch (error) {
        console.error('Error in component:', error);
        this.selectedLoginMethod = this.activeLoginMethod
        this.$swal.fire({
          icon: 'error',
          title: this.$t('staff.staff_update_login_method_failed'),
          text: Array.isArray(error.message) ? error.message[0] : error.message
        })
      }
    },

    // Update the Line OTP status 
    async updateLineOTPStatus(item) {
      const { id, phone, status_line_otp } = item
      
      try {
        const sendData = {
          id: id,  // This will be mapped to adminId in the API service
          status: status_line_otp === 1 ? 0 : 1
        }

        const res = await this.$store.dispatch('lineOtpAuth/setAdminLineOtpStatus', sendData)
        if (res.status === 200) {
          await this.getStaff(1, 25, null, null)
          this.$swal.fire(
            status_line_otp === 1 
              ? this.$t("staff.staff_closed_line_otp")
              : this.$t("staff.staff_opened_line_otp"),
            '',
            'success'
          )
        } else {
          this.$swal.fire(
            status_line_otp === 1 
              ? this.$t("staff.staff_unclose_line_otp")
              : this.$t("staff.staff_unopen_line_otp"),
            '',
            'error'
          )
        }
      } catch (error) {
        console.error('Full error object:', error);
        // Add better error handling for permission denied
        const errorMessage = error.response?.data?.message || error.message;
        this.$swal.fire({
          icon: 'error',
          title: this.$t('staff.update_failed'),
          text: errorMessage
        })
      }
    },
    async updateTelegramOTPStatus(item) {
      console.log('Full item data:', item); // Debug log
      const { id, phone, status_telegram_otp } = item;

      try {

        console.log('Current status:', status_telegram_otp); // Debug log

        const sendData = {
          id: id,
          status: status_telegram_otp === 1 ? 0 : 1
        };


        console.log('Sending data:', sendData); // Debug log

        const res = await this.$store.dispatch('telegramOtpAuth/setAdminTelegramOtpStatus', sendData);
        console.log('Response:', res); // Debug log

        if (res.status === 200) {
          await this.getStaff(1, 25, null, null);
          this.$swal.fire(
            status_telegram_otp === 1
              ? this.$t("staff.staff_closed_telegram_otp")
              : this.$t("staff.staff_opened_telegram_otp"),
            '',
            'success'
          );
        } else {
          this.$swal.fire(
            status_telegram_otp === 1
              ? this.$t("staff.staff_unclose_telegram_otp")
              : this.$t("staff.staff_unopen_telegram_otp"),
            '',
            'error'
          );
        }
      } catch (error) {
        console.error('Full error object:', error);
        const errorMessage = error.response?.data?.message || error.message;
        this.$swal.fire({
          icon: 'error',
          title: this.$t('staff.update_failed'),
          text: errorMessage
        });
      }
    },

    shouldShowColumn(columnValue) {
      if (columnValue === 'status_2fa') {
        return this.activeLoginMethod === '2fa'
      }
      if (columnValue === 'status_line_otp') {
        return this.activeLoginMethod === 'lineOtp'
      }
      return true
    },

    async fetchAvailableLoginMethods() {
      try {
        const response = await this.$store.dispatch('getAvailableLoginMethods');
        if (response) {
          this.availableMethods = response;
        }
      } catch (error) {
        console.error('Failed to fetch available authentication methods:', error);
        // Handle error: show a message, use default methods, etc.
      }
    },
    async initializeLoginMethod() {
      try {
        const response = await this.$store.dispatch('getSettingFeature');

        if (response) {
          const loginMethodSetting = response.find(setting => setting.loginMethod);

          if (loginMethodSetting) {
            this.selectedLoginMethod = loginMethodSetting.loginMethod;
            this.activeLoginMethod = loginMethodSetting.loginMethod;
          }
        }
      } catch (error) {
        console.error('Failed to initialize login method:', error);
      }
    },
  },
}
</script>

<style scoped>
@media (max-width: 550px) {
  .spacer {
    width: 100%;
  }
}
</style>
