<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <v-card>
      <v-card-title>
        Permission
        <v-spacer />
        <v-btn text icon @click="$emit('setPermissionDialog', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-actions class="px-0">
        <v-data-table style="width: 100%" mobile-breakpoint="0" :headers="headers" :items="permissions"
          :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" item-key="path" hide-default-footer
          disable-pagination class="elevation-1" @input="selectPermission($event)">
          <template v-slot:[`item.use`]="{ item }">
            <div class="chk-box">
              <v-checkbox v-model="item.use" @click="selectPermission(item)"></v-checkbox>
            </div>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <div class="chk-box" v-if="item.page === 'สมาชิก' || item.page === 'สร้างคูปอง' || item.page === 'ถอนเงิน'">
              <v-checkbox v-model="item.edit" @click="selectPermissionEdit(item)"></v-checkbox>
            </div>
          </template>
          <!-- <template v-slot:item.calories="{ item }">
            <v-chip :color="getColor(item.calories)" dark>
              {{ item.calories }}
            </v-chip>
          </template> -->
        </v-data-table>
      </v-card-actions>
      <!-- <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="success" @click="updatePermission" :loading="loading">บันทึก</v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import TextInput from '@/components/input/TextInput.vue'
export default {
  components: {
    TextInput,
  },
  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
    permissions: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      selected: [],
      loading: false,
      headers: [
        {
          text: '',
          value: 'use',
          sortable: false,
        },
        {
          text: this.$t("staff.staff_path_name"),
          value: 'page',
          sortable: false,
        },
        {
          text: this.$t("staff.staff_edit_role"),
          value: 'edit',
          sortable: false,
          align: 'center',
          width: '100px'
        },
      ],
    }
  },
  async created() { },
  methods: {
    async selectPermission(values) {
      const params = {
        id: values.id,
        use: values.use,
      }
      this.loading = true
      try {
        await this.$store.dispatch('updatePermission', params)

        // this.$swal.fire('บันทึก permission สำเร็จ', '', 'success')
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e,
          })
        }
      }
      this.loading = false
    },
    async selectPermissionEdit(values) {
      const params = {
        id: values.id,
        edit: values.edit,
      }
      this.loading = true
      try {
        await this.$store.dispatch('updatePermissionEdit', params)

        // this.$swal.fire('บันทึก permission สำเร็จ', '', 'success')
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e,
          })
        }
      }
      this.loading = false
    }
  },
}
</script>
<style scoped>
.chk-box {
  display: flex;
  justify-content: center !important;
}
</style>