<template>
  <v-dialog v-model="dialog" width="600px" persistent min-height="800">
    <v-card>
      <v-card-title>
        Role
        <v-spacer />
        <v-btn text icon @click="$emit('setDialog', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-actions class="pb-0 d-flex align-start">
        <span>
          <text-input :label="$t('staff.staff_role_name')" :text="roleName" @setValue="roleName = $event" />
        </span>
        <v-spacer />
        <span>
          <v-btn color="primary" @click="createRole" :loading="createLoading">{{ $t("staff.staff_create") }}</v-btn>
        </span>
      </v-card-actions>
      <v-card-actions>
        <v-data-table style="width: 100%" mobile-breakpoint="0" :headers="headers" :items="roles" :loading="createLoading"
          :items-per-page="pagination.rowsPerPage" :footer-props="{
            'items-per-page-options': [10, 25, 30, 40, 50],
          }" :no-data-text="$t('not_found')" :no-results-text="$t('not_found')">
          <template v-slot:[`item.permission`]="{ item }">
            <v-btn color="warning" class="white--text text-capitalize" @click="managePermissions(item)">
              permission
            </v-btn>
          </template>
          <template v-slot:[`item.deleteAction`]="{ item }">
            <v-btn color="error" class="white--text text-capitalize" @click="deleteRole(item)" text>
              <v-icon>mdi-delete</v-icon>
              {{ $t("delete") }}
            </v-btn>
          </template>
        </v-data-table>
        <set-permission :dialog="showPermissionAction" :permissions="permissions"
          @setPermissionDialog="showPermissionAction = $event" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TextInput from '@/components/input/TextInput.vue'
import SetPermission from '@/components/staff/roles/SetPermission.vue'
export default {
  components: {
    TextInput,
    SetPermission,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPermissionAction: false,
      roleName: '',
      createLoading: false,
      tableLoading: false,
      headers: [
        {
          text: this.$t("staff.staff_role_name"),
          value: 'name',
          sortable: false,
        },
        {
          text: 'Permission',
          value: 'permission',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t("delete"),
          value: 'deleteAction',
          align: 'center',
          sortable: false,
        },
      ],
      roles: [
        {
          name: 'superAdmin',
          permission: [],
        },
      ],
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      permissions: [],
    }
  },
  async created() {
    await this.getRole()
  },
  methods: {
    async getRole() {
      this.roles = []
      this.roleName = ''
      this.tableLoading = true
      try {
        this.roles = await this.$store.dispatch(
          'getMangeRole',
          `page=${this.pagination.page}&rows=${this.pagination.rowsPerPage}`,
        )
        // this.$swal.fire('สร้าง role สำเร็จ', '', 'success')
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
      this.tableLoading = false
    },
    async createRole() {
      this.createLoading = true
      if (this.roleName !== '') {
        try {
          await this.$store.dispatch('addRole', { name: this.roleName })
          this.$swal.fire('สร้าง role สำเร็จ', '', 'success')
          await this.getRole()
        } catch (e) {
          if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
            this.$swal.fire({
              icon: 'error',
              title: e.message,
            })
          }
        }
      }

      this.createLoading = false
    },
    deleteRole(item) {
      this.$swal
        .fire({
          icon: 'warning',
          title: this.$t("alert"),
          text: `${this.$t("staff.staff_delete_confirm")} ${item.name}`,
          showCancelButton: true,
          confirmButtonText: this.$t("confirm"),
          cancelButtonText: this.$t("cancel"),
        })
        .then(async result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            await this.$store.dispatch('deleteRole', item.id)
            this.$swal.fire(this.$t("staff.staff_delete_complete"), '', 'success')
            const result = await this.getRole()
            //  this.pagination.totalItems = result.count
            // this.value = result.data
            this.loading = false
          }
        })
    },
    managePermissions(item) {
      this.permissions = item.permission

      // Convert name page
      item.permission?.forEach((el, i) => {
        switch (el.page) {
          case 'withdraw':
            this.permissions[i].page = 'ถอนเงิน'
            break;
          case 'dashboard':
            this.permissions[i].page = 'หน้าแรก'
            break;
          case 'staff':
            this.permissions[i].page = 'พนักงาน'
            break;
          case 'user':
            this.permissions[i].page = 'สมาชิก'
            break;
          case 'userLastPlay':
            this.permissions[i].page = 'สมาชิกเล่นล่าสุด'
            break;
          case 'agent':
            this.permissions[i].page = 'พันธมิตร'
            break;
          case 'ref':
            this.permissions[i].page = 'แนะนำเพื่อน'
            break;
          case 'bonus':
            this.permissions[i].page = 'โบนัสแนะนำเพื่อน'
            break;
          case 'returnbalance':
            this.permissions[i].page = 'โบนัสคืนยอดเสีย'
            break;
          case 'coupon':
            this.permissions[i].page = 'สร้างคูปอง'
            break;
          case 'deposit':
            this.permissions[i].page = 'รายการเครดิต'
            break;
          case 'creditwait':
            this.permissions[i].page = 'รายการรอฝาก(คงค้าง)'
            break;
          case 'credit':
            this.permissions[i].page = 'รายการรอฝาก'
            break;
          case 'settingBank':
            this.permissions[i].page = 'ตั้งค่าธนาคาร'
            break;
          case 'logBank':
            // this.permissions.splice(i, 1)
            this.permissions[i].page = 'Log ธนาคาร'
            break;
          case 'reportBonus':
            this.permissions[i].page = 'รายงานโบนัส'
            break;
          case 'logDepositWithdraw':
            this.permissions[i].page = 'Log ฝาก-ถอน'
            break;
          case 'logAccount':
            this.permissions[i].page = 'Log สมาชิก'
            break;
          case 'logSms':
            this.permissions[i].page = 'Log SMS'
            break;
          case 'logPage':
            this.permissions[i].page = 'Log เปิดหน้าเว็บ'
            break;
          case 'logExport':
            this.permissions[i].page = 'Log Export ข้อมูล'
            break;
          case 'logLineNotify':
            this.permissions[i].page = 'Log Line notify'
            break;
          case 'logWheel':
            this.permissions[i].page = 'Log วงล้อ'
            break;
          case 'logCoupon':
            this.permissions[i].page = 'Log คูปอง'
            break;
          case 'logTransferOut':
            this.permissions[i].page = 'รายงานสรุปฝาก-ถอน'
            break;
          case 'promotion':
            this.permissions[i].page = 'โปรโมชั่น'
            break;
          case 'news':
            this.permissions[i].page = 'ประกาศ'
            break;
          case 'settingWebsite':
            this.permissions[i].page = 'ตั้งค่าเว็บ'
            break;
          case 'accountingDepositReport':
            this.permissions[i].page = 'รายงานฝาก (บัญชี)'
            break;
          case 'accountingWithdrawReport':
            this.permissions[i].page = 'รายงานถอน (บัญชี)'
            break;
          case 'logCashbackSetting':
            this.permissions[i].page = 'Log ปิดเปิดโบนัสคืนยอดเสีย'
            break;
          default:
            break;
        }
      })

      this.showPermissionAction = true
    },
  },
}
</script>
